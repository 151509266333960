@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap');
@layer utilities {
    .hide-scrollbar::-webkit-scrollbar {
      display: none; /* Hides scrollbar for webkit browsers (Chrome, Safari, etc.) */
    }
  
    .hide-scrollbar {
      -ms-overflow-style: none; /* Hides scrollbar for IE/Edge */
      scrollbar-width: none; /* Hides scrollbar for Firefox */
    }
  }
  .dynamic-svg path {
    fill: #525252; /* Default fill */
    transition: fill 0.3s ease; /* Smooth transition */
  }

 
  .path {
    fill: #1794dd; /* changes the color to red */
  }
  rect {
    fill: #00ff95; /* changes the color to red */
  }