.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll-container  {
    scroll-behavior: smooth;
}


.loader {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 10px;
    margin-top: 20px;
  }
  
  .loader-dot {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000; /* Adjust color as needed */
    animation: loaderAnimation 1s infinite alternate;
  }
  
  .loader-dot:nth-child(2) {
    animation-delay: 0.2s; /* Adjust delay between dots */
  }
  
  .loader-dot:nth-child(3) {
    animation-delay: 0.4s; /* Adjust delay between dots */
  }
  
  @keyframes loaderAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px); /* Adjust bounce height */
    }
  }


  /* Adjust size and spacing of dots as needed */
  